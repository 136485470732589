import type { MutableRefObject } from 'react'
import { useEffect, useRef, useState } from 'react'

const pollUntil = async <T>(
  callbacksRef: MutableRefObject<{
    poll: () => Promise<T>
    checkResponse: (response: T) => boolean
  }>,
  intervalMS: number,
  abortSignal: AbortSignal,
  setNextUpdate: (date: Date | null) => void
) => {
  let response: T
  do {
    if (abortSignal.aborted) {
      return
    }

    setNextUpdate(new Date(Date.now() + intervalMS))
    await new Promise((res) => {
      setTimeout(res, intervalMS)
    })

    // NECESSARY LINT DISABLE: (eslint-disable-next-line @typescript-eslint/no-unnecessary-condition)
    // If removed failing test case for aborted, as one more poll is happening before abort
    if (abortSignal.aborted) {
      return
    }

    response = await callbacksRef.current.poll()
  } while (!callbacksRef.current.checkResponse(response))
  setNextUpdate(null)
}

export const usePollUntil = <T>(
  poll: () => Promise<T>,
  checkResponse: (response: T) => boolean,
  intervalMS: number,
  enabled: boolean
) => {
  const [nextUpdate, setNextUpdate] = useState<Date | null>(null)
  const callbacksRef = useRef({ poll, checkResponse })
  useEffect(() => {
    callbacksRef.current.poll = poll
    callbacksRef.current.checkResponse = checkResponse
  }, [poll, checkResponse])

  useEffect(() => {
    if (enabled) {
      const abortController = new AbortController()
      void pollUntil(
        callbacksRef,
        intervalMS,
        abortController.signal,
        setNextUpdate
      )
      return () => {
        abortController.abort()
        setNextUpdate(null)
      }
    }
  }, [intervalMS, enabled, callbacksRef, setNextUpdate])

  return {
    nextUpdate,
  }
}

export default pollUntil
