import { useState } from 'react'

import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styled } from 'styled-components'

import { DeviceType, useDeviceInfo } from '@b-stock/bstock-next'
import { Tooltip, FormattedMessage } from '@b-stock/bstock-react'
import { Typography } from '@b-stock/bstock-react/design-system'
import { designColors } from '@b-stock/bstock-react/theme'

type PopcornElement = HTMLElement | null

const InfoIcon = styled(FontAwesomeIcon)`
  color: ${designColors.primary.default};
  margin-left: 4px;
`

const Popcorn = styled.div`
  ${Typography.Body4}
  white-space: nowrap;
`

const PopcornInfo = ({ amount }: { amount: number }) => {
  const [popcornElement, setPopcornElement] = useState<PopcornElement>(null)
  const { deviceType } = useDeviceInfo()
  const isDesktop = deviceType === DeviceType.DESKTOP

  return (
    <>
      <Popcorn ref={isDesktop ? undefined : setPopcornElement}>
        <FormattedMessage
          id="SearchResultCard.popcorn"
          defaultMessage="SearchResultCard.popcorn"
          values={{ amount: amount.toString() }}
        />
        {isDesktop ? (
          <div ref={setPopcornElement}>
            <InfoIcon icon={faInfoCircle} />
          </div>
        ) : null}
      </Popcorn>

      {popcornElement && (
        <Tooltip referenceElement={popcornElement}>
          <FormattedMessage
            id="Tooltip.Content.popcornBidding"
            defaultMessage="Tooltip.Content.popcornBidding"
          />
        </Tooltip>
      )}
    </>
  )
}

export default PopcornInfo
