import { isFuture } from 'date-fns'

import type { SearchAuction } from '@queries/listingSearchQuery/types'

const getIsWithinBuyNowWindow = (auction: SearchAuction) => {
  if (
    auction.buyNow?.enabled &&
    auction.buyNow.endTime &&
    !auction.closed &&
    !auction.canceled
  ) {
    return isFuture(auction.buyNow.endTime)
  }
  return false
}

export default getIsWithinBuyNowWindow
