import { useEffect, useCallback, useState } from 'react'

import type { Channel } from 'pusher-js'
import Pusher from 'pusher-js'

import type {
  LimitedAuction,
  BidListItem,
  Auction,
  LimitedAuctionListItem,
} from '@b-stock/auction-api-client'
import {
  useAccessToken,
  getAuctionWithBid,
  BidStatus,
  getBidStatus,
} from '@b-stock/bstock-next'

import getPusherCredentials from '@helpers/getPusherCredentials'
import { usePollUntil } from '@helpers/pollUntil'
import sha256 from '@helpers/sha256'
import useAccountId from '@helpers/useAccountId'

type PublicAuctionUpdateEventAuctionInfo = Omit<
  LimitedAuction,
  'attributes' | 'upcoming' | 'userBidAmount' | 'winning'
>

type PublicAuctionUpdateEvent = PublicAuctionUpdateEventAuctionInfo & {
  endTime: string
  winningBidId: string
  winningAccountHash: string
}

type PrivateAuctionUpdateEvent = Omit<BidListItem, 'canceled' | 'attributes'>

export type AuctionUpdates = { auctionId: string } & (
  | {
      type: 'public'
      data: PublicAuctionUpdateEventAuctionInfo
    }
  | {
      type: 'status'
      data: {
        bidStatus: BidStatus
        winning: boolean
        closed?: boolean
      }
    }
  | {
      type: 'private'
      data: {
        winning: boolean
        bid: BidListItem | null
        userBidAmount: number
        bidStatus: BidStatus
      }
    }
  | {
      type: 'poll'
      data: Awaited<ReturnType<typeof getAuctionWithBid>>
    }
)

export type UpdateAuctionDataFunction = (
  update: Readonly<AuctionUpdates>
) => { bidStatus: BidStatus } | undefined

const subscribeToPusherUpdates = ({
  publicChannel,
  privateChannel,
  auctionId,
  accountId,
  updateAuctionData,
}: {
  publicChannel: Channel
  privateChannel: Channel | null
  auctionId: string
  accountId: string | null
  updateAuctionData: UpdateAuctionDataFunction
}) => {
  const processPublicUpdate = async ({
    winningAccountHash,
    winningBidId,

    // endTime is intentionally omitted from auctionData to guarantee we don't rely on it anywhere.
    // The pusher message sends `actualEndTime` for both `endTime` and `actualEndTime`
    // for backward compatibility with old FE code only - once this new logic is deployed
    // to production, the BE will stop sending endTime in the pusher message.
    endTime,

    ...auctionData
  }: PublicAuctionUpdateEvent) => {
    const hashIfWinner = await sha256(`${auctionId}${winningBidId}${accountId}`)
    const isWinner = hashIfWinner === winningAccountHash

    // Optimistically update the UI based on the data we got from pusher
    const updatedAuction = updateAuctionData({
      auctionId: auctionData._id,
      type: 'public',
      data: auctionData,
    })
    if (!updatedAuction) {
      throw new Error(
        'updatedListing was not returned by updateAuctionData - this is unexpected'
      )
    }

    const wasWinner =
      updatedAuction.bidStatus === BidStatus.WINNING ||
      updatedAuction.bidStatus === BidStatus.WON

    if (auctionData.closed) {
      if (updatedAuction.bidStatus !== BidStatus.NO_BID) {
        // The auction is closed - update the bid status to won/lost as appropriate
        updateAuctionData({
          auctionId: auctionData._id,
          type: 'status',
          data: {
            bidStatus: isWinner ? BidStatus.WON : BidStatus.LOST,
            winning: isWinner,
            closed: auctionData.closed,
          },
        })
      }
    } else if (!wasWinner && isWinner) {
      // If the user is now the winner because they placed a new bid, there will also be a
      // `new-bid-placed` private channel event and this update is unnecessary... but if
      // they're now the winner because another user's winning bid was canceled they won't
      // get that event... so we need to manually update their bid status.
      updateAuctionData({
        auctionId: auctionData._id,
        type: 'status',
        data: {
          bidStatus: BidStatus.WINNING,
          winning: true,
        },
      })
    } else if (wasWinner && !isWinner) {
      // We'll assume the private `new-bid-placed` channel event will cover the bid/bidStatus
      // for the winners... but the previous winner won't get one of those private channel
      // events, so we need to update their bidStatus manually
      updateAuctionData({
        auctionId: auctionData._id,
        type: 'status',
        data: {
          bidStatus: BidStatus.LOSING,
          winning: false,
        },
      })
    }
  }

  publicChannel.bind('new-bid-placed', processPublicUpdate)
  publicChannel.bind('bid-canceled', processPublicUpdate)
  publicChannel.bind('auction-closed', processPublicUpdate)
  publicChannel.bind('auction-canceled', processPublicUpdate)

  if (privateChannel) {
    const processPrivateUpdate = (bidData: PrivateAuctionUpdateEvent) => {
      let normalizedBid: BidListItem | null
      if (!bidData.bidId) {
        // If the message has no bidId, then it must have been a bid-canceled message and
        // the  user had no remaining non-canceled bids.
        normalizedBid = null
      } else {
        // Either it's a new-bid-placed event, or a bid-canceled event and the user had other, non-canceled bids.
        // In both cases, the message data is from their most recent non-canceled bid.
        normalizedBid = {
          // Not included in the message, but we can assume it isn't canceled when we get this message
          canceled: false,
          ...bidData,
        }
      }

      // Optimistically update the UI based on the data we got from pusher
      // We'll assume a public channel event(s) will update the other auction details as appropriate
      updateAuctionData({
        auctionId: bidData.auctionId,
        type: 'private',
        data: {
          winning: normalizedBid?.winning ?? false,
          bid: normalizedBid,
          userBidAmount: normalizedBid?.bidAmount ?? 0,
          bidStatus: getBidStatus(normalizedBid),
        },
      })
    }
    privateChannel.bind('new-bid-placed', processPrivateUpdate)
    privateChannel.bind('bid-canceled', processPrivateUpdate)
  }
}

const PUSHER_OFFLINE_POLL_INTERVAL_MS = 30_000
const PUSHER_OFFLINE_POLL_CHECK = (
  auction: Awaited<ReturnType<typeof getAuctionWithBid>>
) => {
  return auction.closed || auction.canceled
}

const useAuctionUpdates = ({
  listingId,
  auction,
  updateAuctionData,
  onManualPollScheduled,
}: {
  listingId: string
  auction: Auction | LimitedAuction | LimitedAuctionListItem
  updateAuctionData: UpdateAuctionDataFunction
  onManualPollScheduled: (nextManualPoll: Date | null) => void
}) => {
  const [isPusherUnavailable, setIsPusherUnavailable] = useState(false)

  const accessToken = useAccessToken()
  const accountId = useAccountId()
  const auctionId = auction._id

  const fetchUpdatedAuction = useCallback(async () => {
    // Refetch the auction and (possibly) user bid record
    const updatedAuction = await getAuctionWithBid({
      listingId,
      accessToken,
      accountId,
    })

    // Update again with fresh data from the endpoints
    updateAuctionData({
      auctionId,
      type: 'poll',
      data: updatedAuction,
    })

    return updatedAuction
  }, [listingId, accessToken, updateAuctionData, accountId, auctionId])

  const pusherChannels =
    'pusherChannels' in auction ? auction.pusherChannels : undefined
  useEffect(() => {
    const pusherCredentials = getPusherCredentials()
    const pusherClient = new Pusher(...pusherCredentials)
    pusherClient.connection.bind('unavailable', () => {
      setIsPusherUnavailable(true)
    })
    pusherClient.connection.bind('connected', () => {
      setIsPusherUnavailable(false)
    })

    const publicChannel = pusherClient.subscribe(`auction-${auctionId}`)
    const privateChannel = pusherChannels?.privateChannelName
      ? pusherClient.subscribe(pusherChannels.privateChannelName)
      : null

    subscribeToPusherUpdates({
      publicChannel,
      privateChannel,
      auctionId,
      accountId,
      updateAuctionData,
    })

    return () => {
      publicChannel.unbind_all()
      privateChannel?.unbind_all()
      pusherClient.connection.unbind_all()
      pusherClient.disconnect()
    }
  }, [accountId, auctionId, pusherChannels, updateAuctionData])

  // If pusher is down, and the auction hasn't closed yet, poll for auction updates every 30s
  const { nextUpdate: nextManualPollUpdate } = usePollUntil(
    fetchUpdatedAuction,
    PUSHER_OFFLINE_POLL_CHECK,
    PUSHER_OFFLINE_POLL_INTERVAL_MS,
    isPusherUnavailable && !auction.closed && !auction.canceled
  )

  useEffect(() => {
    onManualPollScheduled(nextManualPollUpdate)
  }, [nextManualPollUpdate, onManualPollScheduled])
}

export default useAuctionUpdates
