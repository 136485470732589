import type { SearchAuction } from '@queries/listingSearchQuery/types'

import getIsWithinBuyNowWindow from './getIsWithinBuyNowWindow'

const getCurrentPrice = (auction: SearchAuction) => {
  if (getIsWithinBuyNowWindow(auction) && auction.buyNow?.price) {
    return auction.buyNow.price
  }
  return Math.max(auction.startPrice, auction.winningBidAmount)
}

export default getCurrentPrice
